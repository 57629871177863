import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { preset } from 'vue-cli-plugin-vuetify-preset-reply/preset'

Vue.use(Vuetify)

export default new Vuetify({
  preset,
  theme: { dark: false },
  icons: {
    iconfont: 'md' || 'mdi' // 'mdiSvg' || 'mdi' || 'fa' || 'fa4' || 'faSvg'
  }
})
