import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VStripeElements from 'v-stripe-elements/lib'
import VueCurrencyInput from 'vue-currency-input'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib' // Globally import VTextField
import router from './router'
import VueAnalytics from 'vue-analytics'
import i18n from './i18n'
import store from './store'

Vue.config.productionTip = false
Vue.use(VStripeElements)
Vue.use(VueCurrencyInput)
Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, {
  locale: 'en',
  decimalLength: 2,
  min: null,
  max: null,
  autoDecimalMode: true,
  defaultValue: 0,
  allowNegative: false
})

Vue.use(VueAnalytics, {
  id: 'UA-111682496-1'
})

new Vue({
  vuetify,
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
