import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { fstore } from '@/plugins/firebase'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    totalAmt: '',
    // donationAmt: '',
    // numTickets: '',
    disableConfirmBtn: true,
    disableCancelBtn: false,
    stripeDonationResponse: null,
    stripeEventResponse: null,
    errResponse: '',
    yt_records: null,
    fb_records: null,
    donationTransaction: null,
    eventTransaction: null,
    event: null
  },
  mutations: {
    ...vuexfireMutations,
    setTotal (state, payload) {
      state.totalAmt = payload.amount
    },
    setConfirmDisable (state) {
      state.disableConfirmBtn = true
    },
    setConfirmEnable (state) {
      state.disableConfirmBtn = false
    },
    setCancelDisable (state) {
      state.disableCancelBtn = true
    },
    setCancelEnable (state) {
      state.disableCancelBtn = false
    },
    setStripeDonationResponse (state, payload) {
      state.stripeDonationResponse = payload
    },
    setStripeEventResponse (state, payload) {
      state.stripeEventResponse = payload
    },
    setErrResponse (state, payload) {
      state.errResponse = payload
    },
    setEvent (state, payload) {
      state.event = payload
    }
  },
  actions: {
    totalAction ({ commit }, payload) {
      commit('setTotal', payload)
    },
    disableConfirmAction ({ commit }) {
      commit('setConfirmDisable')
    },
    enableConfirmAction ({ commit }) {
      commit('setConfirmEnable')
    },
    disableCancelAction ({ commit }) {
      commit('setCancelDisable')
    },
    enableCancelAction ({ commit }) {
      commit('setCancelEnable')
    },
    stripeDonationResponseAction ({ commit }, payload) {
      commit('setStripeDonationResponse', payload)
    },
    stripeEventResponseAction ({ commit }, payload) {
      commit('setStripeEventResponse', payload)
    },
    errMsgAction ({ commit }, payload) {
      commit('setErrResponse', payload)
    },
    bindYouTubeCollection: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        'yt_records',
        fstore.collection('cabaret_live').doc('youtube')
      )
    }),
    bindFacebookCollection: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        'fb_records',
        fstore.collection('cabaret_live').doc('facebook')
      )
    }),
    bindDonationTransactionCollection: firestoreAction(({ bindFirestoreRef }, payload) => {
      return bindFirestoreRef(
        'donationTransaction',
        fstore.collection('cabaretFlamenco_Montreal_transactions').doc(payload)
      )
    }),
    bindEventTransactionCollection: firestoreAction(({ bindFirestoreRef }, payload) => {
      return bindFirestoreRef(
        'eventTransaction',
        fstore.collection('cabaret_events').doc(payload.eventId).collection('transactions').doc(payload.transactionId)
      )
    }),
    bindEventsCollection: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        'event',
        fstore.collection('cabaret_events').where('active', '==', true)
      )
    })
  },
  getters: {
    getTotal (state) {
      return state.totalAmt
    },
    getDonationAmt (state) {
      return state.donationAmt
    },
    getQuantity (state) {
      return state.numTickets
    },
    getConfirmBtnStatus (state) {
      return state.disableConfirmBtn
    },
    getCancelBtnStatus (state) {
      return state.disableCancelBtn
    },
    getStripeDonationResponse (state) {
      return state.stripeDonationResponse
    },
    getStripeEventResponse (state) {
      return state.stripeEventResponse
    },
    getErrResponse (state) {
      return state.errResponse
    },
    getDonationTransactionDetails (state) {
      return state.donationTransaction
    },
    getEventTransactionDetails (state) {
      return state.eventTransaction
    },
    getYoutubeDetails (state) {
      return state.yt_records
    },
    getFacebookDetails (state) {
      return state.fb_records
    },
    getEventDetails (state) {
      return state.event
    }
  }

})
