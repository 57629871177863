<template>
  <v-app>
    <v-main>
      <LandingTopNavbar></LandingTopNavbar>
      <v-container>
      <router-view/>
       </v-container>

    </v-main>
     <v-row align="end" justify="end" class="mr-6 mb-6 pb-6 pr-6">
 <v-speed-dial v-model="options" bottom left direction="top" transition="slide-y-reverse">
      <template v-slot:activator>
        <v-btn large dark v-model="options" fab color="secondary">
   <v-icon color="primary" v-if="options">
     close
   </v-icon>
   <v-icon color="primary" v-else>
     info
     </v-icon>
 </v-btn>
      </template>
      <v-tooltip left>
      <template v-slot:activator ="{on,attrs}">
      <v-btn
        fab
        dark
        small
        color="secondary"
        v-bind="attrs"
        v-on="on"
        href="https://forms.gle/vs9jfTN7HbgqSFXT8"
        target="_blank"
      >
        <v-icon color="primary">rate_review</v-icon>
      </v-btn>
      </template>
      <span>{{$t('tooltip.report_problem')}}</span>
      </v-tooltip>
      <!-- <v-tooltip left>
        <template v-slot:activator ="{on,attrs}">
      <v-btn
        fab
        dark
        small
        color="secondary"
        v-on="on"
        v-bind="attrs"
      >
        <v-icon color="primary">help</v-icon>
      </v-btn>
        </template>
        <span>FAQ's</span>
      </v-tooltip> -->
      <v-tooltip left>
        <template v-slot:activator ="{on,attrs}">
      <v-btn
        fab
        dark
        small
        color="secondary"
        v-on="on"
        v-bind="attrs"
        @click.stop="openDialog"
      >
        <v-icon color="primary">mail</v-icon>
      </v-btn>
        </template>
        <span>{{$t('tooltip.contact')}}</span>
      </v-tooltip>
    </v-speed-dial>
    <v-dialog v-model="contact" max-width="600">
      <LandingContactForm v-on:close-dialog="closeDialog"></LandingContactForm>
    </v-dialog>
 </v-row>
    <LandingFooter></LandingFooter>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    options: '',
    contact: false
  }),
  components: {
    LandingTopNavbar: () => import(/* webpackChunkName: "LandingTopNavbar" */'./components/LandingTopNavbar'),
    LandingFooter: () => import(/* webpackChunkName: "LandingFooter" */'./components/LandingFooter'),
    LandingContactForm: () => import(/* webpackChunkName: "LandingContactForm" */'./components/LandingContactForm')
  },
  methods: {
    closeDialog () {
      this.contact = false
    },
    openDialog () {
      this.contact = true
      // console.log('Contact form clicked')
    }
  }
}
</script>
